import React from "react";

import Faq from "../components/FAQ/Faq";
import Loading from "../components/Loading/Loading";
import Activity from "../components/MembersOverviewComponents/Activity/Activity";
import Footer from "../components/MembersOverviewComponents/Footer/Footer";
import Header from "../components/MembersOverviewComponents/Header/Header";
import MembersNavbar from "../components/MembersOverviewComponents/MembersNavbar/MembersNavbar";
import MyAccount from "../components/MembersOverviewComponents/MyAccount/MyAccount";
import TextSection from "../components/MembersOverviewComponents/TextSection/TextSection";
import ProductSlider from "../components/ProductsSlider/Slider";
import { useGetUserOverviewDataQuery } from "../graphql/__generated__/graphql_types";
import { useAppContext } from "../utils/contextLib";

const MemberOverviewPage = () => {
  const { firebaseUser } = useAppContext();
  const { data, loading } = useGetUserOverviewDataQuery({
    variables: {
      email: firebaseUser!.email!,
    },
  });

  if (loading) return <Loading />;

  console.log(data?.getUserOverviewData);

  return (
    <>
      <MembersNavbar />
      <Header
        totalAmountOfPoints={data!.getUserOverviewData.totalAmountOfPoints}
        name={data!.getUserOverviewData.firstName}
      />
      <Activity points={data!.getUserOverviewData.Points} />
      <TextSection />
      <ProductSlider />
      <MyAccount
        firstName={data!.getUserOverviewData.firstName}
        lastName={data!.getUserOverviewData.lastName}
        phoneNumber={data!.getUserOverviewData.phoneNumber}
      />
      <Faq />
      <Footer />
    </>
  );
};

export default MemberOverviewPage;
