import React, { useState } from "react";

import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import { ReactComponent as ResetIcon } from "../../assets/img/Icon-open-reload.svg";
// import { ReactComponent as Line } from "../../assets/img/Line-1.svg";
import { ReactComponent as Logo } from "../../assets/img/LOYYO-logo-landscape-black.svg";
import "./NavbarComponent.scss";
import {
  useGetTerminalListQuery,
  useTriggerTerminalForPaymentMutation,
} from "../../graphql/__generated__/graphql_types";

const NavbarComponet = ({
  backIcon,
  terminalOption,
  selectedTerminal,
  selectTerminal,
}: {
  backIcon: boolean;
  terminalOption: boolean;
  selectedTerminal?: string;
  selectTerminal?: (terminalId: string) => void;
}) => {
  console.log("selectedTerminal", selectedTerminal);
  const [triggerTerminal] = useTriggerTerminalForPaymentMutation();
  const { data, loading } = useGetTerminalListQuery();
  const [terminalList, setTerminalList] = useState<
    { name: string; terminalId: string }[]
  >([]);

  if (data && !loading) {
    if (data.getTerminalList.length !== terminalList.length) {
      setTerminalList(
        data.getTerminalList.map((terminal) => ({
          name: terminal.name,
          terminalId: terminal.terminalId,
        }))
      );
    }

    console.log("terminalList", terminalList);

    if (!selectedTerminal && selectTerminal) {
      const loyyoLiverDefaultTerminal = terminalList.find(
        (terminal) => terminal.terminalId === "V400m-347336118"
      );

      if (loyyoLiverDefaultTerminal) {
        selectTerminal!(loyyoLiverDefaultTerminal.terminalId);
      } else {
        selectTerminal!(terminalList[0]?.terminalId);
      }
    }
  }

  const triggerPayment = async () => {
    if (selectedTerminal) {
      await triggerTerminal({
        variables: {
          terminalId: selectedTerminal!,
        },
      })
        .then(() => console.log("Hallo"))
        .catch((e) => {
          toast.error(
            "Er ging iets mis. Is de juiste terminal geselecteerd en staat de terminal aan?"
          );
          console.log({ e });
        });
    } else {
      toast.error("Selecteer een terminal");
    }
  };

  const handleChange = (selected: any) => {
    selectTerminal!(selected.terminalId);
  };

  const isClearable: boolean = false;
  const isSearchable: boolean = false;
  const iff = (condition: boolean, then: any, otherwise: any) =>
    condition ? then : otherwise;

  const colourStyles = {
    // color: "red",
    control: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      padding: "0 8px",
      //   backgroundColor: "#fff",
      borderColor: isFocused || isSelected ? "#fff" : "#fff",

      boxShadow: "rgba(0, 0, 0, 0.16) 3px 3px 6px 0px",
      color: "#110B3D",
      paddingRight: 0,
      //   maxWidth: "200px"
      fontSize: 18,
      height: 50,
      width: 230,
      marginLeft: "auto",
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      color: "#110B3D",
      backgroundColor: iff(
        isDisabled,
        null,
        iff(isSelected, "#fff", isFocused)
      ),
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && "#110B3D",
      },
      ":hover": {
        //   ...styles[":active"],
        backgroundColor: !isDisabled && "#110b3d41",
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    menuList: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "none",
    }),
    dropdownIndicator: (styles: any, { isFocused }: any) => ({
      ...styles,
      color: isFocused ? "#110B3D" : "#110B3D",
      ":hover": {
        ...styles[":active"],
        color: "#110B3D",
      },
    }),
  };

  const refreshInfo = () => {
    window.location.assign("/interface");
    console.log("backIcon", backIcon);
  };

  return (
    <Navbar
      expand="lg"

      // style={{
      //   background: `url(${NavBg})`,
      //   backgroundSize: "cover"
      // }}
    >
      {/* <img className="position-absolute bg-graphic" src={NavBg} alt="bg" /> */}
      <Container className="w-100 d-flex justify-content-between align-items-center">
        <Link to="/">
          <Button
            variant="d"
            className="border-0 text-main-text btn-reset text-uppercase"
            onClick={refreshInfo}
          >
            <span className="bg-white d-inline-block p-2 border-r-5 me-3 shadow-b">
              <ResetIcon />
            </span>
            <span className="d-none d-sm-inline-block ">Restart</span>
          </Button>
        </Link>
        <Navbar.Brand className="mx-auto position-sm-static position-absolute">
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {terminalOption && (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end pe-4"
          >
            <Nav className="d-flex flex-row justify-content-evenly">
              {!backIcon && (
                <button
                  type="button"
                  className="btn btn-none btn-typewriter col-4 offset-lg-6 col-lg-2 fs-4"
                  onKeyPress={triggerPayment}
                  tabIndex={0}
                  onClick={triggerPayment}
                >
                  <i className="fa-solid fa-cash-register" />
                </button>
              )}
              <Select
                styles={colourStyles}
                placeholder="select"
                classNamePrefix="select"
                defaultValue={
                  selectedTerminal
                    ? terminalList.find(
                        (terminal) => terminal.terminalId === selectedTerminal
                      )
                    : { name: "select", terminalId: "" }
                }
                value={
                  selectedTerminal
                    ? terminalList.find(
                        (terminal) => terminal.terminalId === selectedTerminal
                      )
                    : { name: "select", terminalId: "" }
                }
                //   isDisabled={isDisabled}
                //   isLoading={isLoading}
                isClearable={isClearable}
                //   isRtl={isRtl}
                isSearchable={isSearchable}
                name="member"
                options={terminalList}
                getOptionLabel={(terminal: { name: string }): any => (
                  <span>{terminal?.name}</span>
                )}
                getOptionValue={(terminal: {
                  name: string;
                  terminalId: string;
                }) => terminal?.terminalId}
                onChange={handleChange}
              />
            </Nav>
          </Navbar.Collapse>
        )}
        {/* <span className="bg-white d-none d-lg-inline-flex justify-content-center align-items-center p-2 border-r-5 shadow-b nav-ico c-pointer">
          <span className="d-block">
            <span className="d-flex">
              <Line />
            </span>
            <span className="d-flex">
              <Line />
            </span>
            <span className="d-flex">
              <Line />
            </span>
          </span>
        </span> */}
      </Container>
    </Navbar>
  );
};

NavbarComponet.defaultProps = {
  selectedTerminal: undefined,
  selectTerminal: undefined,
};

export default NavbarComponet;
