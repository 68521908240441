import React from "react";

import logo from "../assets/YOURLOGO.png";
import Register from "../components/Register/Register";
import "./RegisterPage.scss";

const RegisterPage = () => (
  <>
    <div id="registerPage-div">
      <div className="logo-img-block-sm d-block d-lg-none">
        <img className="logo-img" alt="logo" src={logo} />
      </div>
      <div className="login-container container d-flex justify-content-center align-items-center">
        <div className="main-block row">
          <div className="logo-block col-lg-6 d-lg-flex d-none justify-content-center align-items-center">
            <img alt="logo" src={logo} />
          </div>
          <div className="login-block col-12 col-lg-6 d-lg-flex flex-column bg-light px-3 px-md-5 py-3 py-md-5">
            <div className="col-12 text-center">
              <p className="fs-3 fw-bold">REGISTRATION</p>
              <p className="fs-6 text-muted">Enter your details to register</p>
            </div>
            <div className="login-component">
              <Register />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default RegisterPage;
