import React from "react";

import { Table } from "react-bootstrap";

import { Points } from "../../../graphql/__generated__/graphql_types";

const Activity = ({ points }: { points: Points[] }) => {
  const renderedPoints = points
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    )
    .slice(0, 8)
    .map(({ amount, createdAt, id }) => (
      <tr key={id} className="border-bottom">
        <td>{new Date(createdAt).toLocaleDateString("NL")}</td>
        <td className="text-end">{amount}</td>
      </tr>
    ));

  return (
    <div id="activity" className="bg-dark p-5 d-flex justify-content-center">
      <div className="col-12 col-sm-8 col-md-6">
        <p className="text-light text-center fs-4 fw-bolder">Account History</p>
        <Table borderless variant="dark">
          <thead>
            <tr className="justify-content-between">
              <th className="text-start" scope="col">
                Date
              </th>
              <th className="text-end" scope="col">
                Points
              </th>
            </tr>
          </thead>
          <tbody>{renderedPoints}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default Activity;
