import React, { Dispatch, SetStateAction } from "react";

import Modal from "react-modal";
import { toast } from "react-toastify";

import { useDeleteUserMutation } from "../../graphql/__generated__/graphql_types";

import "./DeleteAccountModal.scss";

const DeleteAccountModal = (props: {
  isOpen: boolean;
  emailAddress: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isOpen, emailAddress, setIsModalOpen } = props;

  const [deleteUserMutation] = useDeleteUserMutation({
    variables: { userEmail: emailAddress },
  });

  const deleteUserAccount = () => {
    deleteUserMutation()
      .then(() => {
        localStorage.clear();
        window.location.assign("/login");
      })
      .catch(() => toast.error("Er ging iets mis..."));
  };

  Modal.setAppElement("#root");
  return (
    <Modal
      id="deleteAccountModal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsModalOpen(!isOpen)}
      className="col-12 d-flex justify-content-center align-items-center"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 2000 } }}
    >
      <div
        className="rounded-3 col-11 col-md-10 col-lg-8 p-5"
        style={{ background: "#f2f4f6" }}
      >
        <div className="col-12 d-flex justify-content-center align-items-center flex-column">
          <h2 className="fw-bold">
            You are about to remove your account. <br />{" "}
          </h2>
          <p className="text-center">
            All your saved points will removed with this action, as well as your
            registered cards. <br /> Confirm below to delete your account
            permanentely.
          </p>
        </div>
        <div className="p-3 col-12 d-flex justify-content-center align-items-center">
          <button
            onClick={() => setIsModalOpen(false)}
            type="button"
            className="btn btn-link text-muted me-2"
          >
            Cancel
          </button>
          <button
            onClick={deleteUserAccount}
            type="button"
            className="btn btn-danger ms-2"
          >
            Delete Account
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
