import React, { useEffect } from "react";

import wallet from "../../../assets/add-to-wallet.png";
import { useAppContext } from "../../../utils/contextLib";

import "./Header.scss";

const Header = ({
  totalAmountOfPoints,
  name,
}: {
  totalAmountOfPoints: number;
  name: string;
}) => {
  const { firebaseUser } = useAppContext();

  const getCardUrl = () => {
    window.location.assign(
      `${process.env.REACT_APP_REMOTE_URL}/api/wallets/pass/download/${firebaseUser?.uid}`
    );
  };

  // Hack for showing image on MacOS devices.
  useEffect(() => {
    if (navigator.vendor === "Apple Computer, Inc.") {
      document
        .querySelector("#bg-image")
        ?.classList.replace("bg-fixed", "bg-scroll");
    }
  }, []);

  return (
    <div id="home">
      <div className="welcome-block text-center">
        <p className="fs-4 fw-bold mb-0">Welcome,</p>
        <p className="fs-3 fw-light">{name}</p>
      </div>

      <div
        id="bg-image"
        className="d-flex align-items-center justify-content-center"
      >
        <div className="points-block text-center">
          <p className="points-p fw-bold">{totalAmountOfPoints}</p>
        </div>
      </div>

      <div
        id="wallet-block"
        className="mx-0 my-2 my-sm-0 row d-flex align-items-center justify-content-center"
      >
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center justify-content-center">
          <button
            className="col-12 btn-none"
            type="button"
            onClick={getCardUrl}
          >
            <img className="col-8" src={wallet} alt="wallet" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
