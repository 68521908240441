import React, { ChangeEvent, FormEvent, useState } from "react";

import { Link, Redirect } from "react-router-dom";

import firebase from "../../config/firebase";
import { useAppContext } from "../../utils/contextLib";

import "./Login.scss";

const Login = () => {
  const [formData, setFormData] = useState({
    emailAddress: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [loginError, setLoginError] = useState<string[] | string>([]);

  const handleLogin = async () => {
    setLoading(true);
    const { emailAddress, password } = formData;

    try {
      // If Email is not verified yet.
      // if (isEmailVerified && !isEmailVerified.emailVerified) {
      //   setShowSendNewLinkURL(true);
      //   throw new Error(
      //     `Verifieer jouw e-mailadres eerst. Volg de stappen uit de e-mail die we hebben verzonden toen je registreerde. `
      //   );
      // }

      await firebase
        .auth()
        .signInWithEmailAndPassword(emailAddress, password)
        .then(() => {
          userHasAuthenticated(true);
          setLoading(false);
        })
        .catch(() => {
          const message =
            "The login failed. The email or the password are not valid.";
          setLoading(false);
          setLoginError(message);
          userHasAuthenticated(false);
        });
    } catch (error) {
      const typedError = error as Error;
      // Catch error if email is not verified yet

      setLoading(false);
      setLoginError(typedError.message);
      userHasAuthenticated(false);
      setLoginError([]);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    // const { email } = formData;

    // We check if the email is verified in our db. If not, let user first do
    // that via the email they received when registered
    // checkIfMailIsVerified({
    //   variables: {
    //     email: normalizedEmail,
    //   },
    // });

    await handleLogin();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError("");
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  if (!loading && isAuthenticated) return <Redirect to="/" />;

  return (
    <form
      onSubmit={handleSubmit}
      id="login-form"
      className="bg-light d-flex flex-column align-items-stretch justify-content-evenly"
    >
      <div className="input-block">
        <label htmlFor="validationCustomEmail" className="form-label">
          Email
        </label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupEmail">
            <i className="fa fa-envelope" />
          </span>
          <input
            type="text"
            className="form-control"
            id="validationCustomEmail"
            aria-describedby="inputGroupEmail"
            placeholder="johnsmith@example.com"
            name="emailAddress"
            onChange={handleInputChange}
            value={formData.emailAddress}
          />
          <div className="invalid-feedback">...</div>
        </div>
        <label htmlFor="validationCustomPassword" className="form-label mt-4">
          Password
        </label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupPassword">
            <i className="fa fa-lock" />
          </span>
          <input
            type="password"
            className="form-control"
            id="validationCustomPassword"
            aria-describedby="inputGroupPassword"
            placeholder="************"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
          />
          <div className="invalid-feedback" />
        </div>
        <span>
          <Link
            to="/reset-password"
            className="forgot-btn text-muted text-nowrap"
          >
            Forgot the password?
          </Link>
        </span>
      </div>

      {Array.isArray(loginError) && loginError?.length ? (
        loginError.map((e, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="text-danger fs-6">
            {e}
          </div>
        ))
      ) : (
        <div className="text-danger fs-6">{loginError}</div>
      )}

      <div className="login-btn-block col-12 col-md-10 offset-md-1">
        <button
          className="login-btn btn btn-primary col-12 col-lg-8"
          type="submit"
        >
          LOGIN
        </button>
        <Link
          className="register-btn text-muted text-decoration-none text-nowrap"
          to="/register"
        >
          Or click here to register
        </Link>
      </div>
    </form>
  );
};

export default Login;
