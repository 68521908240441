import React from "react";

const Loading = () => (
  <div
    style={{ height: "100vh" }}
    className="d-flex text-light bg-dark justify-content-center align-items-center"
  >
    <div className="spinner-border text-light me-3" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="fs-3">Loading...</div>
  </div>
);

export default Loading;
