import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
} from "react";

import Modal from "react-modal";
import { toast } from "react-toastify";

import {
  useUpdateUserAccountMutation,
  GetUserOverviewDataDocument,
} from "../../graphql/__generated__/graphql_types";

import "./ChangeAccountModal.scss";

interface IObjectKeys {
  [key: string]: string | number;
}

interface IAccountData extends IObjectKeys {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

const ChangeAccountModal = (props: {
  isOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  accountData: IAccountData;
}) => {
  const [updateUser] = useUpdateUserAccountMutation();
  const { isOpen, setIsModalOpen, accountData } = props;
  const [initialAccountData] = useState(accountData);
  const [accountFormData, setAccountFormData] = useState(accountData);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAccountFormData({
      ...accountFormData,
      [name]: value,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // TODO: When removing Name from database, also change object on backend for testing if equal.
    updateUser({
      variables: {
        userEmailFromClient: initialAccountData.email,
        updateUserAccountInput: {
          email: accountFormData.email,
          firstName: accountFormData.firstName,
          lastName: accountFormData.lastName,
          phoneNumber: accountFormData.phoneNumber,
        },
      },
      refetchQueries: [
        {
          query: GetUserOverviewDataDocument,
          variables: {
            email: accountFormData.email,
          },
        },
      ],
    })
      .then(({ data }) => {
        toast.success("Gegevens zijn aangepast");

        if (data?.updateUserAccountData.isEmailChanged)
          window.location.assign("/");

        setIsModalOpen(!isOpen);
      })
      .catch((error) => {
        let message;
        if (
          error?.graphQLErrors[0]?.extensions?.exception?.response?.message
            ?.length
        ) {
          [message] =
            error.graphQLErrors[0].extensions.exception.response.message;
        } else if (
          error?.graphQLErrors[0]?.extensions?.exception?.code === "P2002"
        ) {
          message = `${error.graphQLErrors[0]?.extensions?.exception?.meta?.target[0]} is al in gebruik door een account.`;
        } else {
          message = error.message;
        }

        toast.error(message);
      });
  };

  Modal.setAppElement("#root");
  return (
    <Modal
      id="changeAccountModal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsModalOpen(!isOpen)}
      className="col-12 d-flex justify-content-center align-items-center"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 2000 } }}
    >
      <div
        className="rounded-3 col-11 col-md-10 col-lg-8"
        style={{ background: "#f2f4f6" }}
      >
        <form
          onSubmit={handleSubmit}
          className="col-12 d-flex justify-content-center align-items-center flex-column"
        >
          <div className="text-center py-4">
            <h1 className="fw-bold">Account</h1>
            <p>Change your data</p>
          </div>
          <div className="col-11 col-md-7 row">
            <div className="col-6 ps-0 pe-2">
              <label htmlFor="validationCustomFirstName" className="form-label">
                First Name
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupFirstName">
                  <i className="fa fa-user" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustomFirstName"
                  aria-describedby="inputGroupFirstName"
                  placeholder="John"
                  name="firstName"
                  onChange={handleInputChange}
                  value={accountFormData.firstName}
                />
                <div className="invalid-feedback">...</div>
              </div>
            </div>
            <div className="col-6 pe-0 ps-2">
              <label htmlFor="validationCustomLastName" className="form-label">
                Last Name
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupLastName">
                  <i className="fa fa-user" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustomLastName"
                  aria-describedby="inputGroupLastName"
                  placeholder="Smith"
                  name="lastName"
                  onChange={handleInputChange}
                  value={accountFormData.lastName}
                />
                <div className="invalid-feedback">...</div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-7 my-2">
            <label htmlFor="validationCustomPhone" className="form-label">
              Mobile Number
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPhone">
                <i className="fa-solid fa-mobile-screen-button" />
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="inputGroupPhone"
                placeholder="0612345678"
                id="phone"
                name="phoneNumber"
                onChange={handleInputChange}
                value={accountFormData.phoneNumber}
              />
              <div className="invalid-feedback">...</div>
            </div>
          </div>

          <div className="col-11 col-md-7 my-2">
            <label htmlFor="validationCustomEmail" className="form-label">
              Email
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupEmail">
                <i className="fa-regular fa-envelope" />
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="inputGroupEmail"
                placeholder="johndoe@email.com"
                id="email"
                name="email"
                onChange={handleInputChange}
                value={accountFormData.email}
              />
              <div className="invalid-feedback">...</div>
            </div>
          </div>
          <p className="col-11 col-md-7 text-start text-muted fs-6 fst-italic fw-bolder">
            Attention: You need to relogin with the new email.
          </p>

          <div className="p-4 col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-center ">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              className="btn-none pt-3"
              type="button"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangeAccountModal;
