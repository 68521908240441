import React from "react";

import { Link as ReactScrollLink } from "react-scroll";

const TextSection = () => (
  <div id="how">
    <div className="bg-light p-5 text-center d-flex justify-content-center">
      <div className="col-12 col-md-8">
        <p className="fs-4 my-3 fw-bold">How to save points?</p>
        <p className="fs-5 text-secondary">
          You can save points in different ways. The best way is to do this
          automatically using your bankcard or Apple Pay. We store a unique code
          of your bankcard in our database. This code is not traceable to your
          bank account or other private data. When you register, we connect your
          account to this code, so we can allocate you your points
          automatically.
        </p>

        <ReactScrollLink className="text-light" to="faq" offset={-50}>
          <button type="button" className="btn btn-dark btn-lg py-3 my-8">
            More information here
          </button>
        </ReactScrollLink>
      </div>
    </div>
  </div>
);

export default TextSection;
