import React from "react";

import "./Faq.scss";
import { Accordion } from "react-bootstrap";

const Faq = () => {
  const faqItems = [
    {
      questionId: 0,
      question: `How can I collect points?`,
      answer: `You can collect points during every purchase. Every euro you spend is worth 1 point. This is connected to your payment method of choice. When you are a member, the points are added automatically every time you use this payment method.`,
    },
    {
      questionId: 1,
      question: `Where can I find my balance?`,
      answer: `You find your balance in your account. Every time you make a purchase, we send you an email with your new points and your total balance.`,
    },
    {
      questionId: 2,
      question: `What are the rewards for my points?`,
      answer: `We have special rewards for our members. Have a look in our stores to find out the reward of the day.`,
    },
    {
      questionId: 3,
      question: `How can I update my personal information?`,
      answer: `You can always change your personal information in "Your Account". Click "Update Profile" and save.`,
    },
    {
      questionId: 4,
      question: `Can I add other payment methods to my account? If yes, how many can I add?`,
      answer: `Of course, you can add as many payment methods as you wish. Let the cashier know you are a member and we connect the new payment method to your account. The cashier will ask for your phone number.`,
    },
    {
      questionId: 5,
      question: `How can I delete my account?`,
      answer: `We are sorry to see you go. In "My account" you can find a link saying "Remove Account". We hope to see you back in the future.`,
    },
    {
      questionId: 6,
      question: `How do you take care of privacy and my bank information?`,
      answer: `Our partners nor Loyyo have access to bank information of our members. We save your bankcard data as a token in our system. All personal information is managed following GDPR regulations.`,
    },
    {
      questionId: 7,
      question: `I made a purchase, but I can't find the points in my total or the history. What can I do to still add the points?`,
      answer: `We would like to invite you to one of our stores, so we can have a look what went wrong and so we can correct it.`,
    },
  ];

  const renderedFaqitem = faqItems.map((faqItem, index) => (
    <Accordion.Item key={faqItem.questionId} eventKey={index.toString()}>
      <Accordion.Header as="h1">{faqItem.question}</Accordion.Header>
      <Accordion.Body className="bg-dark text-light">
        {faqItem.answer}
      </Accordion.Body>
    </Accordion.Item>
  ));

  return (
    <div className="bg-dark py-5 justify-content-center d-flex" id="faq">
      <div className="col-10 col-md-8">
        <p className="text-light fs-4 fw-bold text-center">FAQ</p>
        <div className="my-10">
          <Accordion>{renderedFaqitem}</Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
