/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";

import { Route, Redirect } from "react-router-dom";

import firebase from "../../config/firebase";
import { useCheckTokenLazyQuery } from "../../graphql/__generated__/graphql_types";
// import ErrorPage from '../Error/ErrorPage';
import Loading from "../Loading/Loading";

const RouteGuard = ({ children, ...rest }: any) => {
  const [
    verifyTokenQuery,
    { data, loading: verifyQueryLoading, error, called },
  ] = useCheckTokenLazyQuery();

  const [user, setUser] = useState<firebase.User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe: { (): void; (): void };

    const checkState = async () => {
      unsubscribe = firebase.auth().onAuthStateChanged(async (userAuth) => {
        if (userAuth && userAuth.email) {
          const firebaseToken = await userAuth.getIdToken(true);
          verifyTokenQuery({
            variables: { email: userAuth.email },
          });
          setUser(userAuth);
          localStorage.setItem("twelve-token", firebaseToken);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    };

    checkState();
    return function cleanup() {
      unsubscribe();
    };
  }, [verifyTokenQuery]);

  if (loading || (user && !called) || verifyQueryLoading) return <Loading />;
  if (error)
    return <h1 className="text-black"> Error: {JSON.stringify(error)} </h1>;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && data?.verifyUserToken?.isTokenValid ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default RouteGuard;
