import React, { useState, useEffect } from "react";

import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { ReactComponent as StarIcon } from "../assets/img/Icon-awesome-star.svg";
import { ReactComponent as CheckIcon } from "../assets/img/Icon-feather-check.svg";
import { ReactComponent as ArrowIcon } from "../assets/img/Icon-ionic-ios-arrow-back.svg";
import NavbarComponent from "../components/NavbarComponent/NavbarComponent";
import { useAddManualPaymentMutation } from "../graphql/__generated__/graphql_types";

import "./AddPoints.scss";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  totalAmountOfPoints: 0,
};

const AddPointsPage = () => {
  const [addManualPayment] = useAddManualPaymentMutation();

  const [customerData, setCustomerData] = useState(initialState);

  const history = useHistory();
  const { state } = useHistory().location as any;

  const btnValues = [
    [1, 2, 3, "<"],
    [4, 5, 6, "X"],
    [7, 8, 9, "."],
    [0, ".", "="],
  ];

  const [num, setNum] = useState("");
  const resetCalc = () => {
    setNum("");
  };
  const backSpace = () => {
    setNum(num.slice(0, -1));
  };
  const numClick = (e: any) => {
    e.preventDefault();
    const value = e.target.innerHTML;
    setNum(num + value);
  };

  const showCent = (number: any) => {
    if (number) {
      return `€ ${(parseFloat(number || "0") / 100).toFixed(2)}`;
    }
    return "";
  };
  const showAddpoint = (number: any) => {
    if (number * 1 >= 100) {
      return Math.floor((number * 700) / 100);
    }
    return "0";
  };

  useEffect(() => {
    if (!state?.customerData?.email) history.push("/interface");

    if (state?.customerData) {
      setCustomerData({
        ...state.customerData,
        totalAmountOfPoints: state.totalAmountOfPoints,
      });
    }
  }, [history, state?.customerData, state.totalAmountOfPoints]);

  const handleSubmit = () => {
    addManualPayment({
      variables: {
        amountInCents: num,
        userEmail: customerData.email,
      },
    })
      .then(({ data }) => {
        toast.success(
          `${data?.addManualPoints.amountOfPoints} points have been added to ${customerData.firstName}!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        history.push("/interface", undefined);
      })
      .catch((error) => {
        toast.error(`Er ging iets mis... ${JSON.stringify(error)}`);
      });
  };

  return (
    <div id="addPoints">
      <NavbarComponent backIcon terminalOption={false} />

      <div className="d-flex mt-5 flex-wrap justify-content-evenly align-items-center">
        <div className="cardBox mx-0 storecard addpoints mt-5 mr-0 mr-md-4 ">
          <div className="cardBox-head">
            <h1 className="text-main-text pt-2">{`${customerData?.firstName} ${customerData?.lastName}`}</h1>
            <ul className="list-unstyled d-flex justify-content-between flex-wrap mt-4 pb-3 mx-auto">
              <li className="text-center">
                <StarIcon />
                <span className="d-block text-main-text font-weight-light pt-1">
                  Points
                </span>
                <span className="p-1 mt-2 bg-white border-r-5 text-main-text font-weight-bold d-block">
                  {customerData.totalAmountOfPoints}
                </span>
              </li>
              <li className="align-self-end">
                <h5 className="text-white p-2">+ {showAddpoint(num)} points</h5>
              </li>
            </ul>
            <h5 className="text-white mx-4 text-center">
              Vul rechts het bedrag in van de bestelling. De punten worden
              automatisch berekend.
            </h5>
          </div>
          <div className="cardBox-footer bg-white">
            <Link
              to="/interface"
              className="text-main-text text-decoration-none d-inline-flex align-items-center p-3"
            >
              <ArrowIcon />
              <span className="d-inline-block ms-2 text-xl text-dark">
                Back
              </span>
            </Link>
          </div>
        </div>
        <div className="align-self-end mt-4 mt-md-0">
          <div
            style={{
              maxWidth: 333,
              color: "#979899",
              fontSize: 18,
              overflow: "hidden",
            }}
            className="bg-light  screen-value border-r-5 shadow-b py-3 px-3 text-center mb-3"
          >
            {showCent(num) || "ENTER"}
          </div>
          <div className="numb-pad">
            {btnValues.flat().map((btn) => {
              if (btn === ".") {
                return "";
              }

              let numClass = btn;
              if (btn === "<") {
                numClass = "back";
              } else if (btn === "=") {
                numClass = "equal";
              }

              let numOnClick = numClick;
              if (btn === "X") {
                numOnClick = resetCalc;
              } else if (btn === "<") {
                numOnClick = backSpace;
              } else if (btn === "=") {
                numOnClick = handleSubmit;
              }

              return (
                <Button
                  key={btn}
                  className={`num-${numClass} btn-s`}
                  value={btn}
                  onClick={numOnClick}
                >
                  {btn === "=" ? (
                    <>
                      <span className="shape-btn-equal" /> <CheckIcon />{" "}
                    </>
                  ) : (
                    btn
                  )}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPointsPage;
