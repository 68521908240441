import React, { useState } from "react";

import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import "./MembersNavbar.scss";
import { Link as ReactScrollLink } from "react-scroll";

import logo from "../../../assets/YOURLOGO.png";
import { useCheckTokenQuery } from "../../../graphql/__generated__/graphql_types";
import useLogoutFirebase from "../../../hooks/LogoutHook";
import { useAppContext } from "../../../utils/contextLib";

const MembersNavbar = () => {
  const { firebaseUser } = useAppContext();

  const [logOutFromFirebase] = useLogoutFirebase();
  const [isAdmin, setIsAdmin] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const logOut = () => {
    logOutFromFirebase();
  };

  useCheckTokenQuery({
    variables: { email: firebaseUser?.email as string },
    onCompleted: ({ verifyUserToken }) =>
      setIsAdmin(verifyUserToken?.Role === "ADMIN"),
  });

  const items = [
    { label: "Home", to: "home" },
    { label: "History", to: "activity" },
    { label: "How to save", to: "how" },
    { label: "Stores", to: "stores" },
    { label: "Account", to: "account" },
    { label: "FAQ", to: "faq" },
  ];

  const renderedItems = items.map((item) => (
    <ReactScrollLink
      key={item.label}
      className="navbar-item text-light"
      to={item.to}
      offset={-100}
      onClick={() => {
        setExpanded(false);
      }}
    >
      {item.label}
    </ReactScrollLink>
  ));

  return (
    <Navbar
      id="members-navbar"
      expand={false}
      onToggle={setExpanded}
      expanded={expanded}
      className="fixed-top py-1"
      collapseOnSelect
    >
      <Container>
        <span>
          <></>
        </span>
        <Navbar.Brand href="#">
          <img src={logo} alt="logo" width="100px" />
        </Navbar.Brand>
        <Navbar.Toggle
          className="menu-btn"
          aria-controls="offcanvasNavbar"
          onClick={() => setExpanded(!expanded)}
        >
          <i className="fa fa-bars" />
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="membersNavbar-offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="bg-dark"
        >
          <Offcanvas.Header closeButton closeVariant="white">
            <Offcanvas.Title id="offcanvasNavbarLabel" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="col-11 offset-1 fs-4">
              {renderedItems}
              <Nav.Link onClick={logOut} className="navbar-item text-light">
                Log Out
              </Nav.Link>
              {isAdmin && (
                <Nav.Item className="btn-admin">
                  <Nav.Link
                    href="/interface"
                    className="btn btn-primary text-light text-center fs-5 p-4"
                  >
                    Admin Interface
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default MembersNavbar;
