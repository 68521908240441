import React, { useEffect, useState } from "react";

import Carousel from "nuka-carousel";

import car from "../../assets/product-car.jpeg";
import gameboy from "../../assets/product-gameboy.jpeg";
import sunglasses from "../../assets/product-sunglasses.jpeg";
import ProductCard from "./Product";

import "./Slider.scss";

const ProductSlider = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [slideWidth, setSlideWidth] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600) {
        setSlidesToShow(3);
        setSlideWidth(365);
      } else if (window.innerWidth > 1000) {
        setSlidesToShow(2);
        setSlideWidth(365);
      } else {
        setSlidesToShow(1);
        setSlideWidth(350);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const products = [
    { id: 0, name: "Exclusive Gameboy", price: 500, productImage: gameboy },
    { id: 1, name: "Exclusive glasses", price: 100, productImage: sunglasses },
    { id: 2, name: "Exclusive auto", price: 200, productImage: car },
  ];

  const renderedProducts = products.map((product) => (
    <ProductCard
      key={product.id}
      productName={product.name}
      price={product.price}
      productImage={product.productImage}
    />
  ));

  const carouselProps = {
    defaultControlsConfig: {
      pagingDotsStyle: {
        fill: "white",
      },
    },
    slidesToShow,
    cellSpacing: slidesToShow > 1 ? 15 : 0,
    initialSlideHeight: 440,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    heightMode: "current" as "first" | "current" | "max",
  };

  return (
    <div id="stores" className="bg-dark py-5">
      <p className="fs-4 text-light fw-bold text-center">Redeem Points</p>
      <div
        className="carousel-block my-5"
        style={{ width: `${slideWidth * slidesToShow}px` }}
      >
        <Carousel {...carouselProps}>{renderedProducts}</Carousel>
      </div>
    </div>
  );
};

export default ProductSlider;
