import React from "react";

import { toast } from "react-toastify";

import {
  GetUserOverviewDataDocument,
  useRedeemPointsForProductMutation,
} from "../../graphql/__generated__/graphql_types";
import { useAppContext } from "../../utils/contextLib";
import "./Product.scss";

type ProductCardProps = {
  productName: string;
  price: number;
  productImage: string;
};

const ProductCard = ({
  productName,
  price,
  productImage,
}: ProductCardProps) => {
  const [redeemPointsManually] = useRedeemPointsForProductMutation();
  const { firebaseUser } = useAppContext();

  const redeemPoints = async () => {
    redeemPointsManually({
      variables: {
        amount: price.toString(),
        email: firebaseUser?.email!,
      },
      refetchQueries: [
        {
          query: GetUserOverviewDataDocument,
          variables: { email: firebaseUser?.email! },
        },
      ],
    }).then(({ data }) => {
      toast.success(`${data?.redeemPointsForProduct.amount} points redeemed`);
    });
  };

  // Manual redeeming points here for product after pressing button and show Toast.

  return (
    <div
      id="products-card"
      className="d-flex flex-column bg-light p-4 mb-3 text-start"
    >
      <div className="image-block">
        <img src={productImage} alt="Product" />
      </div>
      <div className="content-block d-flex align-items-center justify-content-between mt-4">
        <div>
          <p className="fs-6 mb-2">{productName}</p>
          <p className="fs-6 text-success fw-bold mb-2">{price} points</p>
        </div>
        <button
          type="button"
          onClick={redeemPoints}
          className="btn-redeeem btn btn-primary btn-lg"
        >
          Buy now
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
