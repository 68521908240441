import React from "react";

import loyyologo from "../../../assets/loyyologo.png";

const Footer = () => (
  <>
    <div className="d-flex py-5 justify-content-center bg-dark text-white fw-bold">
      <a
        className="text-decoration-none"
        href="https://www.loyyo.nl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex py-2 align-items-center">
          <p className="my-0 mx-2 text-light">Powered by</p>
          <div className="d-flex flex-column justify-content-center mx-2">
            <img alt="loyyo logo" src={loyyologo} width="120px" />
          </div>
        </div>
      </a>
    </div>
  </>
);

export default Footer;
